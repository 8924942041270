import { z } from 'zod';

import {
  JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MAX,
  JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MIN,
} from 'modules/Journey/constants';

import { JourneyNodeMatchEnum, JourneyNodeOpEnum } from 'modules/Journey/types/api/nodeCommon';

const JourneyNodeConditionTypeEnum = z.enum([
  'tag',
  'ga_page_view',
  'ga_purchase',
  'ga_add_to_cart',
]);

export type JourneyNodeConditionType = z.output<typeof JourneyNodeConditionTypeEnum>;

const JourneyNodeConditionPageDurationSchema = z.number().nullable();
const JourneyNodeConditionPageViewsSchema = z.number().nullable();
const JourneyNodeConditionDaysWithinSchema = z
  .number()
  .gte(JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MIN)
  .lte(JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MAX);

export type JourneyNodeConditionPageDuration = z.output<
  typeof JourneyNodeConditionPageDurationSchema
>;
export type JourneyNodeConditionPageViews = z.output<typeof JourneyNodeConditionPageViewsSchema>;
export type JourneyNodeConditionDaysWithin = z.output<typeof JourneyNodeConditionDaysWithinSchema>;

/**
 * Note: filter conditions are complex and we're running up against the limitations of Zod here
 * Limitation 1: we can't nest discriminated unions e.g. discriminate by `condition` AND `op`
 * In the future we'd like to be abe to additionally discriminate by `op` to better define `value`
 * Limitation 2: discriminated unions are difficult to transform
 * For this reason additional processing takes place when the schema is used by the editor UI
 */
const JourneyNodeConditionTagDataSchema = z.object({
  condition: z.literal('tag'),
  op: JourneyNodeOpEnum,
  value: z.number().nullable(),
  extra: z.null(),
});

const JourneyNodeConditionGaPageViewDataSchema = z.object({
  condition: z.literal('ga_page_view'),
  op: JourneyNodeOpEnum,
  value: z.string().nullable(),
  extra: z.object({
    page_duration: JourneyNodeConditionPageDurationSchema,
    page_views: JourneyNodeConditionPageViewsSchema,
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionGaPurchaseDataSchema = z.object({
  condition: z.literal('ga_purchase'),
  op: JourneyNodeOpEnum,
  value: z.string().nullable(),
  extra: z.object({
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionGaAddToCartDataSchema = z.object({
  condition: z.literal('ga_add_to_cart'),
  op: JourneyNodeOpEnum,
  value: z.string().nullable(),
  extra: z.object({
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionDataSchema = z.discriminatedUnion('condition', [
  JourneyNodeConditionTagDataSchema,
  JourneyNodeConditionGaPageViewDataSchema,
  JourneyNodeConditionGaPurchaseDataSchema,
  JourneyNodeConditionGaAddToCartDataSchema,
]);

export const JourneyNodeFilterSchema = z.object({
  match: JourneyNodeMatchEnum,
  conditions: z.array(JourneyNodeConditionDataSchema),
});

/**
 * Note that this is the `input` of the schema e.g. what the API returns (and also accepts)
 */
export type JourneyNodeFilterRequestArgs = z.input<typeof JourneyNodeFilterSchema>;
