import { z } from 'zod';

import { JourneyEdgeSchema } from 'modules/Journey/types/api/edge';
import { JourneyNodeSchema } from 'modules/Journey/types/api/node';
import { ISO8601DateSchema } from 'shared/models/common/date';

export const JourneyStatusEnum = {
  draft: 'draft',
  paused: 'paused',
  scheduled: 'scheduled',
  ongoing: 'ongoing',
  ended: 'ended',
} as const;

export const JourneyItemStatusEnum = z.nativeEnum(JourneyStatusEnum);

/**
 * This core schema is reused by the form UI schema
 */
export const JourneyItemBasicSchema = z.object({
  name: z.string(),
  repeat_journey_times: z.number().nullable(),
  line_message_quota: z.number().nullable(),
  sleep_start_time: z.string().nullable(),
  sleep_end_time: z.string().nullable(),
});

/**
 * The standard journey item
 */
export const JourneyItemStandardSchema = JourneyItemBasicSchema.extend({
  id: z.number().int(),
  status: JourneyItemStatusEnum,
  schedule_start_at: ISO8601DateSchema.nullable(),
  schedule_end_at: ISO8601DateSchema.nullable(),
  created_at: ISO8601DateSchema,
  updated_at: ISO8601DateSchema,
});

/**
 * A journey item with attached workflow data; used by both the form and report
 */
export const JourneyItemSchema = JourneyItemStandardSchema.merge(
  z.object({
    nodes: z.array(JourneyNodeSchema),
    paths: z.array(JourneyEdgeSchema),
  }),
);

export type JourneyItemArgs = {
  botId: number;
  journeyItemId?: number;
};

export type JourneyItemParsed = z.output<typeof JourneyItemSchema>;
