import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { AuthAxios } from 'lib/axios';
import { accountApi } from 'shared/api/account';
import { broadcastApi } from 'shared/api/broadcast';
import { broadcastSmsApi } from 'shared/api/broadcastSms';
import { deeplinkApi } from 'shared/api/deeplink';
import { deeplinkBatchApi } from 'shared/api/deeplinkBatch';
import { integrationsApi } from 'shared/api/integrations';
import { journeyApi } from 'shared/api/journey';
import { memberApi } from 'shared/api/member';
import { organizationApi } from 'shared/api/organization';
import { receiptRegisterApi } from 'shared/api/receiptRegister';
import { segmentApi } from 'shared/api/segment';
import { tagApi } from 'shared/api/tag';
import { tracelinkApi } from 'shared/api/tracelink';
import { validationPlugin } from 'shared/lib/zodios/validationPlugin';

const apiClient = new Zodios(
  [
    ...accountApi,
    ...broadcastApi,
    ...broadcastSmsApi,
    ...deeplinkApi,
    ...deeplinkBatchApi,
    ...integrationsApi,
    ...journeyApi,
    ...memberApi,
    ...organizationApi,
    ...receiptRegisterApi,
    ...segmentApi,
    ...tagApi,
    ...tracelinkApi,
  ],
  {
    axiosInstance: AuthAxios,
  },
);

apiClient.use(validationPlugin);

export { apiClient };

export const apiHooks = new ZodiosHooks('maac', apiClient);
