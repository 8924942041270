import { z } from 'zod';

// Note: SMS broadcasts use "failed" instead of "fail" as a status
const BroadcastSmsStatusEnum = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  SENDING: 'sending',
  SENT: 'sent',
  FAIL: 'failed',
} as const;

export const BroadcastSmsStatusSchema = z.nativeEnum(BroadcastSmsStatusEnum);

// TODO: relocate to UI types or merge with BroadcastStatusEnum if possible
export const BroadcastSmsFilterEnum = {
  all: 'all',
  draft: 'draft',
  scheduled: 'scheduled',
  sending: 'sending',
  sent: 'sent',
  failed: 'failed', // Note: regular broadcast items use "fail"
  search: 'search',
} as const;

const BroadcastSmsFilterSchema = z.nativeEnum(BroadcastSmsFilterEnum);

export type BroadcastSmsFilterType = z.output<typeof BroadcastSmsFilterSchema>;
