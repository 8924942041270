import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { apiHooks } from 'shared/api';
import { sanitizeTagName } from 'utils/tag';

const useTagQueryInvalidation = () => {
  const queryClient = useQueryClient();

  return {
    invalidateQueries: useCallback(() => {
      queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagManagerList') });
      queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagManagerListMetrics') });
      queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagMetadata') });
    }, [queryClient]),
  };
};

export const useTagCreateMutation = () => {
  const { invalidateQueries } = useTagQueryInvalidation();

  return apiHooks.useTagCreate(undefined, {
    onMutate: (data) => {
      return { ...data, name: sanitizeTagName(data.name) };
    },
    onSuccess: () => {
      invalidateQueries();
    },
  });
};

export const useTagUpdateMutation = ({ tagId }: { tagId: number }) => {
  const { invalidateQueries } = useTagQueryInvalidation();

  return apiHooks.useTagUpdate(
    { params: { tagId } },
    {
      onMutate: (data) => {
        return { ...data, name: sanitizeTagName(data.name) };
      },
      onSuccess: () => {
        invalidateQueries();
      },
    },
  );
};

export const useTagDeleteMutation = ({ tagId }: { tagId: number }) => {
  const { invalidateQueries } = useTagQueryInvalidation();

  return apiHooks.useTagDelete(
    { params: { tagId } },
    {
      onSuccess: () => {
        invalidateQueries();
      },
    },
  );
};
