import { z } from 'zod';

import { JourneyBranchConditionSplitSchema } from 'modules/Journey/types/api/nodeBranchSettings';
import { JourneyNodeFilterSchema } from 'modules/Journey/types/api/nodeFilter';
import {
  JourneyNodeTypeGaAddToCartSettingsSchema,
  JourneyNodeTypeGaPageViewSettingsSchema,
  JourneyNodeTypeGaPurchaseSettingsSchema,
  JourneyNodeTypeLineMessageOpenedSettingsSchema,
  JourneyNodeTypeSendLineMessageSettingsSchema,
  JourneyNodeTypeTagAddedSettingsSchema,
  JourneyNodeTypeTimeDelaySettingsSchema,
  JourneyNodeTypeTimeScheduleSettingsSchema,
} from 'modules/Journey/types/api/nodeSettings';
import { ObjectEmptySchema } from 'shared/models/common/object';

/**
 * These enums are used to get node category from type alone
 * Include any node types under development in these enums and filter them out as needed
 */
export const JourneyNodeTriggerTypeEnum = z.enum([
  'empty',
  'tag_added',
  'ga_page_view',
  'ga_add_to_cart',
  'ga_purchase',
  'line_message_opened',
]);

export const JourneyNodeRuleTypeEnum = z.enum([
  'exit',
  'time_delay',
  'time_schedule',
  'yes_no_branch',
]);

export const JourneyNodeActionTypeEnum = z.enum(['send_line_message']);

export const JourneyNodeTypeSchema = z.union([
  JourneyNodeTriggerTypeEnum,
  JourneyNodeRuleTypeEnum,
  JourneyNodeActionTypeEnum,
]);

/**
 * Triggers
 */
const JourneyNodeTypeEmptySchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['empty']),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTagAddedSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['tag_added']),
  settings: JourneyNodeTypeTagAddedSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaPageViewSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['ga_page_view']),
  settings: JourneyNodeTypeGaPageViewSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaPurchaseSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['ga_purchase']),
  settings: JourneyNodeTypeGaPurchaseSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaAddToCartSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['ga_add_to_cart']),
  settings: JourneyNodeTypeGaAddToCartSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeLineMessageOpenedSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('trigger'),
  type: z.literal(JourneyNodeTriggerTypeEnum.Values['line_message_opened']),
  settings: JourneyNodeTypeLineMessageOpenedSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

/**
 * Rules
 */
const JourneyNodeTypeExitSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('rule'),
  type: z.literal(JourneyNodeRuleTypeEnum.Values['exit']),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTimeDelaySchema = z.object({
  name: z.string().nullable(),
  category: z.literal('rule'),
  type: z.literal(JourneyNodeRuleTypeEnum.Values['time_delay']),
  settings: JourneyNodeTypeTimeDelaySettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTimeScheduleSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('rule'),
  type: z.literal(JourneyNodeRuleTypeEnum.Values['time_schedule']),
  settings: JourneyNodeTypeTimeScheduleSettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeYesNoBranchSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('rule'),
  type: z.literal(JourneyNodeRuleTypeEnum.Values['yes_no_branch']),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: JourneyBranchConditionSplitSchema.or(ObjectEmptySchema),
});

/**
 * Actions
 */
export const JourneyNodeTypeSendLineMessageSchema = z.object({
  name: z.string().nullable(),
  category: z.literal('action'),
  type: z.literal(JourneyNodeActionTypeEnum.Values['send_line_message']),
  settings: JourneyNodeTypeSendLineMessageSettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeSchemaSet = z.discriminatedUnion('type', [
  JourneyNodeTypeEmptySchema,
  JourneyNodeTypeTagAddedSchema,
  JourneyNodeTypeGaPageViewSchema,
  JourneyNodeTypeGaPurchaseSchema,
  JourneyNodeTypeGaAddToCartSchema,
  JourneyNodeTypeLineMessageOpenedSchema,
  JourneyNodeTypeExitSchema,
  JourneyNodeTypeTimeDelaySchema,
  JourneyNodeTypeTimeScheduleSchema,
  JourneyNodeTypeYesNoBranchSchema,
  JourneyNodeTypeSendLineMessageSchema,
]);
