import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { JourneyItemSchema, JourneyItemStatusEnum } from 'modules/Journey/types/api/item';
import { JourneyNodeSchema } from 'modules/Journey/types/api/node';
import { ListResponseSchema } from 'shared/models/common';
import { ISO8601DateSchema } from 'shared/models/common/date';

const JourneyListReportDataSchema = z.object({
  trigger: z.number().nullable(),
  in_progress: z.number().nullable(),
  exit: z.number().nullable(),
  transaction_revenue: z.number().nullable(),
});

/**
 * This core schema is reused by the form UI schema
 */
export const JourneyItemBasicSchema = z.object({
  name: z.string(),
  repeat_journey_times: z.number().nullable(),
  line_message_quota: z.number().nullable(),
  sleep_start_time: z.string().nullable(),
  sleep_end_time: z.string().nullable(),
});

/**
 * The standard journey item
 */
export const JourneyItemStandardSchema = JourneyItemBasicSchema.extend({
  id: z.number().int(),
  status: JourneyItemStatusEnum,
  schedule_start_at: ISO8601DateSchema.nullable(),
  schedule_end_at: ISO8601DateSchema.nullable(),
  created_at: ISO8601DateSchema,
  updated_at: ISO8601DateSchema,
});

const JourneyListItemSchema = JourneyItemStandardSchema.extend({
  report: JourneyListReportDataSchema,
});

const JourneyCreateParameters = z.object({
  bot_id: z.number().int(),
  name: z.string(),
  schedule_start_at: z.string().nullable().optional(),
  schedule_end_at: z.string().nullable().optional(),
  repeat_journey_times: z.number().int().nullable().optional(),
  line_message_quota: z.number().int().nullable().optional(),
  sleep_start_time: z.string().nullable().optional(),
  sleep_end_time: z.string().nullable().optional(),
  source_journey_id: z.number().int().optional(),
});

export const journeyApi = makeApi([
  {
    alias: 'journeyList',
    method: 'get',
    path: '/journey/v1/journey/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: JourneyItemStatusEnum,
        type: 'Query',
      },
      {
        name: 'name',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: JourneyListItemSchema.strict().array(),
    }),
  },
  {
    alias: 'journeyListMetrics',
    method: 'get',
    path: '/journey/v1/journey/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: JourneyItemStatusEnum,
        type: 'Query',
      },
      {
        name: 'name',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        count: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'journeyDetail',
    method: 'get',
    path: '/journey/v1/journey/:journeyItemId/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyReport',
    method: 'get',
    path: '/journey/v1/journey/:journeyItemId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemCreate',
    method: 'post',
    path: '/journey/v1/journey/',
    parameters: [
      {
        name: 'body',
        schema: JourneyCreateParameters.strict(),
        type: 'Body',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemUpdate',
    method: 'patch',
    path: '/journey/v1/journey/:journeyItemId/',
    parameters: [
      {
        name: 'body',
        schema: JourneyCreateParameters.strict(),
        type: 'Body',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemDelete',
    method: 'delete',
    path: '/journey/v1/journey/:journeyItemId/',
    response: z.never(),
  },
  {
    alias: 'journeyItemPublish',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/publish/',
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemTurnOn',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/on/',
    response: JourneyItemSchema.omit({
      status: true,
    })
      .extend({
        status: z.literal('ongoing').or(z.literal('scheduled')),
      })
      .strict(),
  },
  {
    alias: 'journeyItemPause',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/pause/',
    response: JourneyItemSchema.omit({
      status: true,
    })
      .extend({
        status: z.literal('paused'),
      })
      .strict(),
  },
  {
    alias: 'journeyNodeCreate',
    method: 'post',
    path: '/journey/v1/node/',
    parameters: [
      {
        name: 'journey_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'path_ids',
        schema: z.number().int().array(),
        type: 'Query',
      },
      {
        name: 'type',
        schema: z.string(), // TODO: convert existing enums to const assertions
        type: 'Query',
      },
    ],
    response: JourneyNodeSchema,
  },
  {
    alias: 'journeyNodeUpdate',
    method: 'post',
    path: '/journey/v1/node/:journeyNodeId/',
    parameters: [
      {
        name: 'type',
        schema: z.string().optional(), // TODO: convert existing enums to const assertions
        type: 'Query',
      },
      {
        name: 'settings',
        schema: z.any().optional(), // TODO: complete this schema
        type: 'Query',
      },
      {
        name: 'filters',
        schema: z.string().optional(), // TODO: complete this schema
        type: 'Query',
      },
      {
        name: 'branch_settings',
        schema: z.string().optional(), // TODO: complete this schema
        type: 'Query',
      },
    ],
    response: JourneyNodeSchema,
  },
  {
    alias: 'journeyNodeDelete',
    method: 'delete',
    path: '/journey/v1/node/:journeyNodeId/',
    response: z.never(),
  },
]);
