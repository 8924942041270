import { z } from 'zod';

import type { ValueOf } from 'type-fest';

export const BroadcastStatusEnum = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  SENDING: 'sending',
  SENT: 'sent',
  FAIL: 'fail',
} as const;

export const BroadcastStatusSchema = z.nativeEnum(BroadcastStatusEnum);

export type BroadcastStatus = z.output<typeof BroadcastStatusSchema>;

export const BroadcastChannelEnum = {
  LINE: 'line',
  SMS: 'sms',
} as const;

export type BroadcastChannel = ValueOf<typeof BroadcastChannelEnum>;

// TODO: relocate to UI types or merge with BroadcastStatusEnum if possible
export const BroadcastFilterEnum = {
  all: 'all',
  draft: 'draft',
  scheduled: 'scheduled',
  sending: 'sending',
  sent: 'sent',
  fail: 'fail', // Note: broadcast uses "fail", SMS broadcast uses "failed"
  search: 'search',
} as const;

const BroadcastFilterSchema = z.nativeEnum(BroadcastFilterEnum);

export type BroadcastFilterType = z.output<typeof BroadcastFilterSchema>;
